import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Loader from 'react-loaders';
import Home from './components/views/home.js';
import Category from './components/views/category.js';
import CategoryAll from './components/views/categoryAll.js';
import Detail from './components/views/detail.js';
import Search from './components/views/search.js';
import NoMatch from './components/views/noMatch.js';
import Navigation from './components/elements/navs/navigation.js';
import MobileNavigation from './components/elements/navs/mobileNavigation.js';
import BackToTopButton from './components/elements/buttons/backToTopButton.js';
import Footer from './components/elements/navs/footer.js';
import './scss/app.scss';
import ReactPiwik from 'react-piwik';
import NoMatchBackground from './images/404-image.jpg';

 const piwik = new ReactPiwik({
    url: 'https://weblogs.esource.com',
    siteId: 5,
    trackErrors: true,
  });

class App extends Component {

  constructor(props){
    super(props);
    this.state = {
      URLPaths:{},
      customizations:[],
      articles:[],
      blocks:[],
      caseStudies:[],
      dropdownDisplayBusiness:false,
      dropdownDisplayTech:false,
      businessTypeCategories:[],
      technologyCategories:[],
      categoryData:[],
      categoriesResponse: [],
      blockData: [],
      categoriesData: [],
      breadcrumbLinks: [],
      CtaBlockObj:{},
      cssFile: {},
      ReactPiwik: ReactPiwik,
      toSearch: false,
      scrolling: true,
      isIE9:false,
      accessToken:''
    };
    this.contact = React.createRef();
    //added event listeners must be binded at the constructor
    this.handleBackToTop = this.handleBackToTop.bind(this);
  }

  //loops through categories for tech & business sectors. Returns them as dropdown options and attaches Link component for React Router.
  buildTopNavCategories(categories){
      return categories.map(category => {
        return <Link to={category.path} key={category.id}>{category.name}</Link>
      })
  }

  //loops through categories for tech & business sectors. Returns them as footer.
  buildFooterNavigation(categories) {
      return categories.map(category => {
        return(
          <li key={category.id}>
            <Link to={category.path} key={category.id}>{category.name}</Link>
          </li>
        )
      })
  }

  //creates breadcrumbs
  buildBreadcrumbLinks(view, hierarchyData){
    let breadcrumbLinks;
    if(view === 'categoryPage'){
      //will delete first conditional when parentLink is passed as API response
      let parentLink;
      if(hierarchyData['parent'].toLowerCase() === 'business types'){
        parentLink = this.state.URLPaths.businessTypes
      } else {
        parentLink = this.state.URLPaths.technologies
      }

      breadcrumbLinks = [
        {
          'text': hierarchyData['parent'],
          'href': parentLink
        },
        {
          'text': hierarchyData['name'],
          'href': '#'
        }
      ];
    } else if(view === 'categoryAllPage'){
      breadcrumbLinks = [
        {
          'text': hierarchyData,
          'href': '#'
        }
      ];
    } else if(view === 'detailPage'){
      let parentLink;
      if(hierarchyData['categories'][0]['parent'].toLowerCase() === 'business types'){
        parentLink = this.state.URLPaths.businessTypes
      } else {
        parentLink = this.state.URLPaths.technologies
      }

      breadcrumbLinks = [
         {
           'text': hierarchyData['categories'][0]['parent'],
           'href': parentLink
         },
         {
           'text': hierarchyData['categories'][0]['name'],
           'href': hierarchyData['categories'][0]['path']
         },
         {
           'text': hierarchyData['title'],
           'href': '#'
         }
       ];
    }
    return breadcrumbLinks;
  }

  //Grab all block HTML data and organize them in an object with the unique title as the key, and HTML value as the value. This allows for better readability when passing block data as props to different 'view' components.
  //example: {'home-page-intro':<h1>Business Energy Advisor</h1>}
  buildCTABlockObj(data){
    let result = {};
    for(let i = 0; i < data.length; i++){
      result[data[i].title] = data[i].body;
    }
    return result;
  }

  //Depending on parent type (Business types vs Technologies), determine the language of the block and appropriate button. This is passed down as props.
  renderCTAButton(parentType, viewType){
    if(viewType === 'CategoryAll' && parentType === 'Business types'){
      return(
        <div>
          <div dangerouslySetInnerHTML={{ __html: this.state.CtaBlockObj['explore-business-types-block']}}/>
          <Link className='btn' to={this.state.URLPaths.technologies}>View all technologies</Link>
        </div>
      )
    } else if (viewType === 'CategoryAll' && parentType === 'Technologies'){
      return(
        <div>
          <div dangerouslySetInnerHTML={{ __html: this.state.CtaBlockObj['explore-business-types-block']}}/>
          <Link className='btn' to={this.state.URLPaths.businessTypes}>View all business types</Link>
        </div>
      )
    } else if(parentType === 'Business types'){
      return(
        <div>
          <div dangerouslySetInnerHTML={{ __html: this.state.CtaBlockObj['explore-business-types-block']}}/>
          <Link className='btn' to={this.state.URLPaths.businessTypes}>View more business types</Link>
          <Link className='btn' to={this.state.URLPaths.technologies}>View all technologies</Link>
        </div>
      )
    } else {
      return(
        <div>
          <div dangerouslySetInnerHTML={{ __html: this.state.CtaBlockObj['explore-technologies-block']}}/>
          <Link className='btn' to={this.state.URLPaths.technologies}>View more technologies</Link>
          <Link className='btn' to={this.state.URLPaths.businessTypes}>View all business types</Link>
        </div>
      )
    }
  }

  //Since the browser url will determine our base URL for our fetch calls and we need to parse out the customer key from it, below is a consolidated function to find both and return an object with those results.
  determineCustomerAndBaseUrl(hostname, environmentSettings){
    //setting a result object which will contain the base URL depending on environment type and the customer key
    let result = {};
    if(environmentSettings.NODE_ENV === "production"){
      //we are in 'production mode' (npm run build)
      if(hostname.indexOf('-bea-iris-local') > -1){
        //we are in iris-local
        result['baseUrl'] = environmentSettings.REACT_APP_IRIS_API_URL;
        result['customerKey'] = hostname.substr(0, hostname.indexOf('-bea-iris-local'));
      } else if (hostname.indexOf('-local') > -1){
        //we are in local box
        result['baseUrl'] = environmentSettings.REACT_APP_LOCAL_API_URL;
        result['customerKey'] = hostname.substr(0, hostname.indexOf('-local'));
      } else if (hostname.indexOf('.sb.') > -1) {
        //we are in sandbox
        result['baseUrl'] = environmentSettings.REACT_APP_SB_API_URL;
        result['customerKey'] = hostname.substr(0, hostname.indexOf('.sb.bizenergyadvisor'));
      } else if (hostname.indexOf('.artemis') > -1) {
        result['baseUrl'] = environmentSettings.REACT_APP_PROD_API_URL;
        result['customerKey'] = hostname.substring(0, hostname.indexOf('-bea'));
      } else {
        //we are in production
        result['baseUrl'] = environmentSettings.REACT_APP_PROD_API_URL;
        result['customerKey'] = hostname.substr(0, hostname.indexOf('.bizenergyadvisor'));
      }
    } else {
      //we are in development mode (npm start). Customer is hard coded as test.
      result['baseUrl'] = environmentSettings.REACT_APP_API_URL;
      result['customerKey'] = 'esource'
    }

    return result;
  }

  excludeContent(originalNodeList, excludedNodes){
    return originalNodeList.filter(
      function(node){
        return this.indexOf(node.id) < 0;
      },excludedNodes
    )
  }
  handleBackToTop() {
    if (window.scrollY === 0 ) {
      //if user is at the top of the window and only if state is false, set state to true (this hides Back to top button).
      !this.state.scrolling && (this.setState({scrolling:true}))
    } else {
      this.state.scrolling && (this.setState({scrolling:false}))
    }
  }

  scrollToFooter(){
    window.scrollTo(0, this.contact.current.offsetTop)
  }

  async componentDidMount(){
    //detect if browser is IE 9
    if(navigator.appVersion.indexOf('MSIE 9') > -1){
      ReactPiwik.push(['setCustomUrl', window.location.href]);
      ReactPiwik.push(['trackPageView']);
      this.setState({isIE9:true})
    //if not continue executing App
    } else {
      let envFile = process.env;
      let hostname = window.location.hostname;
      //creating a new array out of process.env results to make information more readable.
      let configuration = this.determineCustomerAndBaseUrl(hostname, envFile);

      const paths = {
        url: configuration.baseUrl,
        adminURL: envFile.REACT_APP_ADMIN_URL,
        categories: envFile.REACT_APP_CATEGORIES,
        businessTypes: envFile.REACT_APP_BUSINESS,
        technologies: envFile.REACT_APP_TECHNOLOGIES,
        article: envFile.REACT_APP_ARTICLE,
        draft: envFile.REACT_APP_DRAFT,
        allArticles: envFile.REACT_APP_ALL_ARTICLES,
        block: envFile.REACT_APP_BLOCK,
        allBlocks: envFile.REACT_APP_ALL_BLOCKS,
        relatedContent: envFile.REACT_APP_RELATED_CONTENT,
        customerCustomizations: envFile.REACT_APP_CUSTOMER_CONFIG,
        search: envFile.REACT_APP_SEARCH_API
      };

      var customizationsResponse;
      var categoriesResponse;
      var blockResponse;
      var excludedArticles;
      var excludedCategories;

      try {
        // request for customizations, customer content, and Google Analytics code
        customizationsResponse = await fetch(`${paths.url}${paths.customerCustomizations}/${configuration.customerKey}?access_token=${envFile.REACT_APP_ACCESS_TOKEN}`, {
          cache: "no-store"
        });

        // This most likely means that this is an invalid subdomain
        if (customizationsResponse.status === 404) {
          console.log('CustomizationsResponse fetch error', customizationsResponse.status);
          this.setState({error:customizationsResponse.status,
            statusText: customizationsResponse.statusText,
            isError: true,
            sub404: true
          })
        }
        else if(customizationsResponse.status !== 200) {
          console.log('CustomizationsResponse fetch error', customizationsResponse.status);
          this.setState({error:customizationsResponse.status,
            statusText: customizationsResponse.statusText,
            isError: true})
        }
        else {
          var customizationsJson = await customizationsResponse.json();
          excludedArticles = (customizationsJson['excluded_content'] === '') ? null : customizationsJson['excluded_content'];
          excludedCategories = (customizationsJson['excluded_categories'] === '') ? null : customizationsJson['excluded_categories'];



          //request for all business sector and technology categories
          categoriesResponse = await fetch(`${paths.url}${paths.categories}?exclude_categories=${excludedCategories}&access_token=${envFile.REACT_APP_ACCESS_TOKEN}`);

          blockResponse = await fetch(`${paths.url}${paths.allBlocks}?access_token=${envFile.REACT_APP_ACCESS_TOKEN}`);
          window.addEventListener('scroll', this.handleBackToTop);


          if(categoriesResponse.status !== 200) {
            console.log('categoriesResponse feth error', categoriesResponse.status);
            this.setState({error:categoriesResponse.status,
              statusText: categoriesResponse.statusText,
              isError: true})
          }
          if(blockResponse.status !== 200) {
            console.log('blockResponse feth error', blockResponse.status);
            this.setState({error:blockResponse.status,
              statusText: blockResponse.statusText,
              isError: true})
          }
        }
      } catch(err) {
        console.log('Feth Error', err);
        this.setState({ isError:true,
          error:400,
          statusText: 'Could not fetch customer information' })
      }

      if(!this.state.isError) {
        try {
          const categoriesJson = await categoriesResponse.json();
          const blockJson = await blockResponse.json();
          this.addCustomerScripts(customizationsJson);
          //make response more readable and store as state
          let CtaBlockObj = this.buildCTABlockObj(blockJson);
          this.setState({
            URLPaths:paths,
            blockData:blockJson,
            customizations:customizationsJson,
            categoriesData:categoriesJson,
            businessTypeCategories: categoriesJson[0]['children'],
            technologyCategories: categoriesJson[1]['children'],
            CtaBlockObj:CtaBlockObj,
            excludedCategories:excludedCategories,
            excludedArticles:excludedArticles,
            accessToken:envFile.REACT_APP_ACCESS_TOKEN
          })

        } catch(err) {
          console.log('Fetch Error', err);
          this.setState({ isError:true, error:400, statusText: 'Problem starting app' })
        }
      }
    }
  }

  /**
   * If provided, adds scripts fetched from the customer API to the page.
   *
   * @param customer
   */
  addCustomerScripts(customer) {
    if(customer.hasOwnProperty('scripts')) {
      for(let x in customer.scripts) {
        const script = customer.scripts[x];
        let attach = document.createElement('script');
        if(script.hasOwnProperty('type') && script.type == 'external') {
          console.log('external');
          attach.src = script.script;
        }
        else if(script.hasOwnProperty('type') && script.type == 'inline') {
          attach.innerHTML = script.script;
        }
        let target = document.head;
        if(script.hasOwnProperty('target')) {
          if(document.hasOwnProperty(script.target)) {
            target = document[script.target];
          }
          else if(document.querySelector(script.target)) {
            target = document.querySelector(script.target);
          }
        }
        if(script.hasOwnProperty('attributes')) {
          for(let y in script.attributes) {
            Object.keys(script.attributes).forEach(key => {
              attach.setAttribute(key, script.attributes[key])
            })
          }
        }
        target.appendChild(attach);
      }
    }
  }
  componentWillUnmount() {
      window.removeEventListener('scroll', this.handleBackToTop);
  }

  render() {
    if(this.state.isIE9 === true){
      return(
        <div className='non-supported-browser'>
          <div className='header-container'>
            <h1>Looks like you’re using an older version of Internet Explorer that isn&#39;t compatible with this tool.</h1>
            <h2>Please update your browser to a newer version or use an alternative, such as Chrome, Firefox, or Safari.</h2>
          </div>
        </div>
      )
    } else if(this.state.isError === true){
      return(
        <Router>
            <div data-test='detail-fetch detail' className='detail'>
              <NoMatch error={this.state.error} statusText={this.state.statusText} sub404={this.state.sub404}/>
            </div>
        </Router>
      )
    } else if(this.state.blockData.length === 0 || Object.keys(this.state.URLPaths).length === 0 || Object.keys(this.state.CtaBlockObj).length === 0
  || this.state.customizations.length === 0){
      return (
        <div
        className='full-page-loader'>
          <Loader type='line-scale'
          style={{transform: 'scale(2)'}}/>
        </div>
      )
    }
    return (
      <div>
        <link rel='stylesheet' type='text/css' href={this.state.customizations['css']} />
        <Helmet>
          <link rel='shortcut icon' href={this.state.customizations['favicon_path']}/>
        </Helmet>
        <Router>
          <div
          data-test='app'
          className='app'>
            {this.state.customizations.subdomain === 'esource2020' &&
              <div className='alert-bar'>
                <p>We want to support utilities and businesses during the COVID-19 pandemic, so we’re granting access to the E&nbsp;Source Business Energy Advisor until June 30, 2020.</p>
              </div>
            }
            <MobileNavigation
              mobileNav={this.state.mobileNav}
              URLPaths={this.state.URLPaths}
              categoriesData={this.state.categoriesData}
              businessCategories={this.state.businessTypeCategories}
              technologyCategories={this.state.technologyCategories}
              buildTopNavCategories={this.buildTopNavCategories.bind(this)}
              buildCustomizations={this.state.customizations}
              scrollToFooter={this.scrollToFooter.bind(this)}
            />
            <Navigation
              data-test='navigation'
              businessCategories={this.state.businessTypeCategories}
              technologyCategories={this.state.technologyCategories}
              dropdownDisplayBusiness={this.state.dropdownDisplayBusiness}
              dropdownDisplayTech={this.state.dropdownDisplayTech}
              URLPaths={this.state.URLPaths}
              buildCustomizations={this.state.customizations}
              buildTopNavCategories={this.buildTopNavCategories.bind(this)}
            />
            <BackToTopButton
              data-test='back-to-top'
              ReactPiwik={this.state.ReactPiwik}
              displayTOC={this.state.displayBacktoTop}
              scrolling={this.state.scrolling}
            />
            <Switch>
              <Route exact
                path='/'
                render={(props) => <Home
                ReactPiwik={this.state.ReactPiwik}
                CtaBlockObj={this.state.CtaBlockObj}
                URLPaths={this.state.URLPaths}
                buildCustomizations={this.state.customizations}
                scrolledTo={this.state.scrolledTo}
                {...props}/>}/>
              <Route exact
                path='/categories/business-types'
                render={(props) => <CategoryAll
                ReactPiwik={this.state.ReactPiwik}
                key='business-types'
                type='Business types'
                URLPaths={this.state.URLPaths}
                categoriesData={this.state.categoriesData[0]}
                categories={this.state.businessTypeCategories}
                ctaHTML={this.state.CtaBlockObj['explore-technologies-block']}
                excludedCategories={this.state.excludedCategories}
                excludedArticles={this.state.excludedArticles}
                buildBreadcrumbLinks={this.buildBreadcrumbLinks.bind(this)}
                renderCTAButton={this.renderCTAButton.bind(this)}
                accessToken={this.state.accessToken}
                {...props}/>}/>
              <Route exact
                path='/categories/technologies'
                render={(props) => <CategoryAll
                ReactPiwik={this.state.ReactPiwik}
                key='technologies'
                type='Technologies'
                URLPaths={this.state.URLPaths}
                categories={this.state.technologyCategories}
                categoriesData={this.state.categoriesData[1]}
                ctaHTML={this.state.CtaBlockObj['explore-business-types-block']}
                toSearch={this.state.toSearch}
                excludedArticles={this.state.excludedArticles}
                excludedCategories={this.state.excludedCategories}
                buildBreadcrumbLinks={this.buildBreadcrumbLinks.bind(this)}
                renderCTAButton={this.renderCTAButton.bind(this)}
                accessToken={this.state.accessToken}
                {...props}/>}/>
              <Route exact
                path='/articles/:id'
                render={(props) => <Category
                ReactPiwik={this.state.ReactPiwik}
                key={props.match.params.id}
                URLPaths={this.state.URLPaths}
                ctaHTMLBusinesses={this.state.CtaBlockObj['explore-business-types-block']}
                ctaHTMLTechnologies={this.state.CtaBlockObj['explore-technologies-block']}
                excludedArticles={this.state.excludedArticles}
                buildBreadcrumbLinks={this.buildBreadcrumbLinks.bind(this)}
                renderCTAButton={this.renderCTAButton.bind(this)}
                accessToken={this.state.accessToken}
                {...props}/>}/>
              <Route exact
                path='/article/:id'
                render={(props) => <Detail
                ReactPiwik={this.state.ReactPiwik}
                key={props.match.params.id}
                URLPaths={this.state.URLPaths}
                chartOrder={this.state.customizations.chart_order}
                chartDefault={this.state.customizations.default_division}
                customerContent={this.state.customizations.content}
                ctaHTMLBusinesses={this.state.CtaBlockObj['explore-business-types-block']}
                ctaHTMLTechnologies={this.state.CtaBlockObj['explore-technologies-block']}
                customerName={this.state.customizations.name}
                excludedArticles={this.state.excludedArticles}
                excludedCategories={this.state.excludedCategories}
                buildBreadcrumbLinks={this.buildBreadcrumbLinks.bind(this)}
                renderCTAButton={this.renderCTAButton.bind(this)}
                accessToken={this.state.accessToken}
                {...props}/>}
              />
              <Route exact
                path='/draft/:id'
                render={(props) => <Detail
                ReactPiwik={this.state.ReactPiwik}
                key={props.match.params.id}
                URLPaths={this.state.URLPaths}
                chartOrder={this.state.customizations.chart_order}
                chartDefault={this.state.customizations.default_division}
                customerContent={this.state.customizations.content}
                ctaHTMLBusinesses={this.state.CtaBlockObj['explore-business-types-block']}
                ctaHTMLTechnologies={this.state.CtaBlockObj['explore-technologies-block']}
                customerName={this.state.customizations.name}
                excludedArticles={this.state.excludedArticles}
                excludedCategories={this.state.excludedCategories}
                buildBreadcrumbLinks={this.buildBreadcrumbLinks.bind(this)}
                renderCTAButton={this.renderCTAButton.bind(this)}
                draft={true}
                accessToken={this.state.accessToken}
                {...props}/>}
              />
              <Route exact
                path='/search/:id'
                render={(props) => <Search
                URLPaths={this.state.URLPaths}
                key={props.match.params.id}
                ReactPiwik={this.state.ReactPiwik}
                accessToken={this.state.accessToken}
                {...props}/>}
              />
              <Route
                render={(props) => <NoMatch
                error={404}
                statusText="Not Found"
                {...props}/>}
              />
            />
            </Switch>
            <div ref={this.contact}>
              <Footer
                data-test='footer'
                URLPaths={this.state.URLPaths}
                buildCustomizations={this.state.customizations}
                businessCategories={this.state.businessTypeCategories}
                technologyCategories={this.state.technologyCategories}
                buildFooterNavigation={this.buildFooterNavigation.bind(this)}
              />
            </div>
          </div>
        </Router>
      </div>
    );
  }
}

export default App;
